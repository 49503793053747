@import "minima";

img.thumbnail {
border:4px solid #fefefe;
box-shadow:0 0 0 1px rgba(10,10,10,.2);
display:inline-block;
line-height:0;
max-width:100%;
transition:box-shadow .2s ease-out;
border-radius:0;  
margin-bottom:1rem;
}
img.thumbnail:focus, .thumbnail:hover {
box-shadow:0 0 6px 1px rgba(33,153,232,.5)
}

img.right, div.right {
float:right;
}

img.left, div.left {
float: left;
}

div.inline-image {
margin: 10px 10px;
background-color: #fff;
padding: 10px;
border-top: 1px solid #999;
border-right: 2px solid #555;
border-bottom: 2px solid #555;
border-left: 1px solid #999;
}

div.inline-image img {
border-top: 2px solid #555;
border-right: 1px solid #999;
border-bottom: 1px solid #999;
border-left: 2px solid #555;
}

div.inline-image span {
display: block;
word-wrap:break-word;
white-space: normal;
font-size: 76%;
margin-top: 0;
}

